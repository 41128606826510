<template>
  <div id='publish-good-step1'>
    <a-form-model ref="formRef" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules" v-bind="formItemLayout">
      <a-form-model-item v-if="isCollect" label="同步上级商品信息" prop="syncFlag">
        <a-switch :checked="form.syncFlag" @click="handleSyncFlagChange" />
        <p slot="extra">
          <a-icon type="warning" :style="{color: '#f56c6c'}" /> 关闭该功能会导致商品信息与上级商品信息不同步
        </p>
      </a-form-model-item>
      <a-form-model-item label="商品名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入" :disabled="isCollect && form.syncFlag && !isPlatform" />
      </a-form-model-item>
      <a-form-model-item label="商品类型" prop="type">
        <a-radio-group name="radioGroup" v-model="form.type" :disabled="isCollect || noEditing" @change='changeProductType'>
          <a-radio value="0">
            商品
          </a-radio>
          <a-radio value="1">
            服务
          </a-radio>
          <a-radio value="2">
            到店消费
          </a-radio>
          <a-radio value="3">
            电子券
          </a-radio>
          <a-radio value="4">
            充值卡
          </a-radio>
          <a-radio value="5">
            旅游门票
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="日历价" prop="calendarPriceFlag" v-if='form.type === "5"'>
        <a-radio-group v-model="form.calendarPriceFlag" :disabled="isCollect && form.syncFlag && !isPlatform">
          <a-radio :value="true">
            是
          </a-radio>
          <a-radio :value="false">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="商品编码" prop="productNo">
        <a-input v-model="form.productNo" placeholder="请输入" :disabled="isCollect && form.syncFlag && !isPlatform" />
      </a-form-model-item>
      <a-form-model-item label="商品标签" prop="type" v-if='form.type !== "0"'>
        <a-checkbox-group :value="form.productTag.split(',')" name="checkboxgroup" :options="tagOptions" @change="onChangeTag" :disabled='isCollect' />
        <div class='tips-box'>
          <span class='label'>随时退：</span>
          <span class='value'>在有效期内允许用户申请退款</span>
        </div>
        <div class='tips-box'>
          <span class='label'>过期退：</span>
          <span class='value'>超过有效期后允许用户申请退款</span>
        </div>
        <div class='tips-red'>注：若”随时退“，”过期退“都不勾选，则说明不支持退款</div>
      </a-form-model-item>

      <a-form-model-item label="商品副标题" prop="subTitle">
        <a-input v-model="form.subTitle" placeholder="请输入" :disabled="isCollect && form.syncFlag && !isPlatform" />
      </a-form-model-item>
      <a-form-model-item label="文本标签" prop="label" placeholder='请输入' extra='例如热卖、新品、促销、爆款等，会在商品名称前展示'>
        <a-input v-model="form.label" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="商品类目" :wrapper-col="{span: 21}" prop="productCategoryId" :extra="needVoucherFlag?'':'准确选择商品类目有助于完善商品信息与数据分析'">
        <div class="category-result">
          <p class="result-text" v-if="categorySelected">{{categorySelected}}</p>
          <a-button v-if="!isCollect" :type="form.productCategoryId ? 'link' : ''" @click="categorySettingShow = true">
            {{form.productCategoryId ? '修改类目' : '选择商品类目'}}
          </a-button>
        </div>
        <div v-if='needVoucherFlag' style='color:red;'>
          {{needVoucher.content}} <a-button style='padding:0;' @click='$router.push("/shop/voucher?id="+needVoucher.manageVoucherId)' type='link'>去上传</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label="商品分组" prop="productGroupId">
        <div style="display:flex">
          <a-spin class="custom-spin" style="flex: 1" :spinning="productGroupLoading" size="small">
            <a-select v-model="form.productGroupId" placeholder="请选择" @change="formRef.clearValidate('productGroupId')">
              <a-select-option v-for="d in productGroupList" :key="d.productGroupId">
                <span class="custom-select-option">{{d.groupName}}</span>
              </a-select-option>
            </a-select>
          </a-spin>
          <div class="operation-box">
            <a-button type="link" @click="getProductGroupList">刷新</a-button>
          </div>
        </div>
      </a-form-model-item>

      <template >
        <a-form-model-item label="商品产地" prop="origin" wx:else>
          <a-input id='tipinput' v-model="form.origin" placeholder="请输入" :disabled="isCollect" />
          <el-amap v-if="form.type==='2'||form.type==='5'" vid="amapDemo" :center="mapInfo.lnglat" :amap-manager="amapManager" :zoom="zoom" :events="events" class="amap-demo" style="height: 300px;margin-top:12px;" :plugin='plugin'>
            <el-amap-marker v-for="(item,index) in markers" :key='index' ref="marker" vid="component-marker" :position="item"></el-amap-marker>
          </el-amap>
        </a-form-model-item>
      </template>
      <a-form-model-item label="视频" v-if="form.video||!isCollect||!form.syncFlag||isPlatform" prop="video" :wrapper-col="{span: 14}" extra="在小程序端商品详情轮播图中第一个位置展示">
        <div class='video-plus' v-if='!form.video' @click='addVideo'>
          <a-icon type="plus" />
          <div class='desc'>添加视频</div>
        </div>
        <div v-else>
          <video class='video' controls :src="baseUrl + form.video">
            当前浏览器不支持 video直接播放，点击这里下载视频： <a :href="baseUrl + form.video" target="_blank">下载视频</a>
          </video>
          <a-button type='link' style='color:red;' v-if='!form.syncFlag||!isCollect||isPlatform' @click='deleteVideo'>删除</a-button>
          <a-button type='link' v-if='!form.syncFlag||!isCollect||isPlatform' @click='addVideo'>更换视频</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label="商品图" prop="picList" extra="建议尺寸：800*800像素，你可以拖拽图片调整顺序，最多上传15张，每张图片不得超过3M。" :wrapper-col="{span: 14}">
        <picture-card-upload :fileList.sync="form.picList" :max="15" draggable :isPreview="isCollect && form.syncFlag && !isPlatform" />
      </a-form-model-item>
      <a-form-model-item label="排序号" prop="seqNum">
        <a-input type='number' v-model="form.seqNum" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="虚拟销量" prop="virtualSale">
        <a-input-number :min="0" v-model="form.virtualSale" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="每日限购数量" prop="limitNum">
        <a-input-number :min="0" v-model="form.limitNum" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label='报单产品' prop="bdFlag" extra="报单产品用户购买会变成创客">
        <a-radio-group v-model='form.bdFlag'>
          <a-radio :value="true">
            是
          </a-radio>
          <a-radio :value="false">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label='是否提现产品' prop="txFlag" extra="提现商品不计算业绩">
        <a-radio-group v-model='form.txFlag'>
          <a-radio :value="true">
            是
          </a-radio>
          <a-radio :value="false">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <p class="sub-title">价格库存</p>

      <sku-setting ref="skuSettingRef" :isPlatform='isPlatform' :countryUnifyFlag='form.countryUnifyFlag' :init-data="initData" :productType='form.type' :syncFlag='form.syncFlag' :is-collect="isCollect" />
      <a-form-model-item :wrapper-col="{span: 18}" label="全国统一零售价" prop="countryUnifyFlag" :extra='isCollect?"若供应商开启全国统一零售价，您采集商品时将不可以编辑“零售价”，“划线价“，“精度值”。":"开启全国统一零售价时，分销商将不能修改“零售价”，“划线价“。"'>
        <a-switch checked-children="是" :disabled='isCollect' un-checked-children="否" v-model='form.countryUnifyFlag' />
      </a-form-model-item>
      <a-form-model-item v-if="form.type === '2'||form.type === '3'||form.type==='4'||form.type==='5'" label="有效期" prop="serviceTimeType" extra="兑换/使用商品的有效期">
        <a-radio-group v-model="form.serviceTimeType" :disabled="isCollect" @change='changeServiceTimeType'>
          <a-radio value="1" v-if="form.type!=='5'">
            <span style="padding:  0 8px;">下单后</span>
            <a-select style="width: 120px" :disabled='form.serviceTimeType === "2" || isCollect' placeholder='请选择' v-model="form.validDay" @change='changeValidDay'>
              <a-select-option v-for="h in 30" :key="h" :value="h">{{h}}</a-select-option>
            </a-select>
            <span style="padding:  0 8px;">天内有效</span>
          </a-radio>
          <a-radio value="2" style='margin-top:12px;'>
            <a-range-picker style='width: 400px;' :disabled='form.serviceTimeType === "1" || isCollect' :show-time="form.type !=='5'" :format="form.type==='5'?'YYYY-MM-DD':'YYYY-MM-DD HH:mm:ss'" :placeholder="['开始时间', '结束时间']" @change="changeDateRange" v-model="dateRange" />
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <p class="sub-title" v-if='form.type === "0"'>物流信息</p>
      <a-form-model-item v-if='form.type === "0"' label="快递运费" prop="shopLogisticsMethod" :extra="isCollect ? '' : '运费模板支持按地区设置运费，按购买件数计算运费，按重量计算运费等'" :wrapper-col="{span: 14}">
        <span v-show="isCollect">该商品为采集商品，暂不支持修改运费、配送信息</span>
        <a-radio-group v-show="!isCollect" v-model="form.shopLogisticsMethod" class="custom-radio-group" :disabled="isCollect">
          <a-radio :style="radioStyle" value="1">
            <div class="custom-radio-content">
              统一邮费
              <a-form-model-item prop="logisticsUnifyPrice">
                <a-input v-model.trim="form.logisticsUnifyPrice" placeholder="请输入" addonBefore="¥" :disabled="isCollect" />
              </a-form-model-item>
            </div>
          </a-radio>
          <a-radio :style="radioStyle" value="2">
            <div class="custom-radio-content">
              运费模版
              <a-form-model-item prop="shopLogisticsId">
                <a-spin class="custom-spin" :spinning="shopLogisticsLoading" size="small">
                  <a-select showSearch v-model="form.shopLogisticsId" placeholder="请选择" :filter-option="(input, option) => option.componentOptions.children[0].text.indexOf(input) >= 0" @change="handleShopLogisticsChange" :disabled="isCollect">
                    <a-select-option v-for="d in shopLogisticsList" :key="d.shopLogisticsId">
                      <span class="custom-select-option">{{d.name}}</span>
                    </a-select-option>
                  </a-select>
                </a-spin>
              </a-form-model-item>
              <div class="operation-box">
                <a-button type="link" @click="getShopLogistics">刷新</a-button>
                <a-button type="link" @click="$router.push('/shop/logistics/add')">新建</a-button>
                <a-button type="link" @click='$router.push("/guid_page/0")'>如何设置合适的运费模版？</a-button>
              </div>
            </div>
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <p class="sub-title">其他信息</p>
      <a-form-model-item label="长期有效" prop="longFlag" :wrapper-col="{span: 14}" extra="非长期有效商品需设置自动下架时间">
        <a-switch v-model='form.longFlag' />
      </a-form-model-item>
      <a-form-model-item label="下架时间" v-if='!form.longFlag' prop="offShelfTime" extra="下架时间受上级分销商/供应商所设置的下架时间影响。以最早的下架时间为准。">
        <a-date-picker format="YYYY-MM-DD HH:mm:ss" valueFormat='YYYY-MM-DD HH:mm:ss' v-model='form.offShelfTime' :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" />
      </a-form-model-item>

      <a-form-model-item label="商品状态" prop="state" :wrapper-col="{span: 14}">
        <a-radio-group v-model="form.state" :disabled="isCollect&&form.syncFlag">
          <a-radio value="1">立即上架</a-radio>
          <a-radio value="2">手动上架</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="起售" prop="startNum" extra="起售数量超出商品库存时，买家无法购买该商品。">
        商品至少
        <a-input-number v-model="form.startNum" :min="1" :disabled="isCollect" />
        件起售
      </a-form-model-item>
    </a-form-model>
    <modal-category-setting :show.sync="categorySettingShow" :ids-map-str.sync="categoryInitStr" @confirm="handleCategoryConfirm" />

    <select-video-dialog idName='publish-good-step1' :show='showSelectVideo' @change='handleSelectDone' :initSelectData='form.video?[form.video]:[]' :multiple='false' :max='1'></select-video-dialog>
  </div>
</template>

<script>
import { AMapManager } from 'vue-amap'
import { common, goods } from '@/api'
import { ref, watch, onMounted, reactive, toRefs } from '@vue/composition-api'
import SkuSetting from './SkuSetting'
import ModalCategorySetting from './ModalCategorySetting'
import pick from 'lodash/pick'
import { productTags } from '@/utils/constants'
import moment from 'moment'
const amapManager = new AMapManager()
const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 8 },
}
const radioStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  lineHeight: '40px',
}
export default {
  name: 'GoodsPublishStep1',

  components: {
    SkuSetting,
    ModalCategorySetting,
  },

  props: {
    initData: Object,
    isCollect: Boolean,
    isPlatform: Boolean,
  },

  setup (props, { root, emit }) {
    const AMap = window.AMap
    const form = ref({
      label: '',
      name: '',
      type: '0',
      subTitle: '',
      productNo: process.env.NODE_ENV === 'development' ? '31680358' : '',
      productGroupId: undefined,
      productCategoryId: '',
      origin: '',
      picList: [],
      shopLogisticsMethod: '1', // 1:物流统一价 2:物流模版
      logisticsUnifyPrice: '0',
      shopLogisticsId: undefined,
      state: '1',
      longFlag: true,
      offShelfTime: '',
      startNum: 1,
      syncFlag: true,
      seqNum: 0,
      virtualSale: 0,
      countryUnifyFlag: false,
      validDay: undefined,
      productTag: '1,2,3',
      serviceTimeType: '0',
      serviceStartTime: '',
      serviceEndTime: '',
      calendarPriceFlag: false,
      limitNum: 99999,
      bdFlag: true,
      txFlag: false,
    })
    const state = reactive({
      mapInfo: {
        // 初始值默认为天安门
        address: '北京市东城区东华门街道天安门',
        lng: 116.397451,
        lat: 39.909187,
        lnglat: [116.397451, 39.909187],
      },
      markers: [],
      zoom: 15,
      amapManager,
      plugin: [
        {
          pName: 'Geolocation',
          enableHighAccuracy: true,
          events: {
            init (o) {
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  if (root.$route.params.productId) return
                  getLocation(result)
                }
              })
            },
          },
        },
      ],
      events: {
        click (e) {
          if (props.isCollect) return
          mapEvent(e)
        },
      },
    })
    onMounted(() => {
      getShopLogistics()
      getProductGroupList()
      initMapByInput()
    })
    const showSelectVideo = ref(false)

    watch(
      () => props.initData,
      async (data) => {
        if (!Object.keys(data).length) return
        const { productGeneral = {}, product = {}, picList = [], productCategory = {} } = data
        Object.assign(form.value, {
          ...pick(product, [
            'name',
            'subTitle',
            'productGroupId',
            'productCategoryId',
            'state',
            'syncFlag',
            'virtualSale',
            'seqNum',
            'video',
            'productNo',
            'longFlag',
            'offShelfTime',
            'label',
            'bdFlag',
            'txFlag',
            'limitNum',
          ]),
          ...pick(productGeneral, [
            'origin',
            'startNum',
            'type',
            'countryUnifyFlag',
            'validDay',
            'productTag',
            'serviceTimeType',
            'serviceStartTime',
            'serviceEndTime',
            'calendarPriceFlag',
            'lngLat',
          ]),
          shopLogisticsId: productGeneral.shopLogisticsId || undefined,
          logisticsUnifyPrice: productGeneral.shopLogisticsId
            ? '0'
            : String(productGeneral.logisticsUnifyPrice),
          shopLogisticsMethod: productGeneral.shopLogisticsId ? '2' : '1',
          picList: picList.filter((a) => a.type !== 1).map((x) => x.picPath),
          video: picList.filter((a) => a.type === 1).length
            ? picList
              .filter((a) => a.type === 1)
              .map((x) => x.picPath)
              .join(',')
            : '',
        })
        Object.keys(form.value).forEach((key) => {
          if (typeof form.value[key] === 'number') form.value[key] = String(form.value[key])
        })
        dateRange.value =
          form.value.serviceStartTime && form.value.serviceEndTime
            ? [
              moment(form.value.serviceStartTime, 'YYYY-MM-DD HH:mm:ss'),
              moment(form.value.serviceEndTime, 'YYYY-MM-DD HH:mm:ss'),
            ]
            : null
        categoryInitStr.value = productCategory.idsMapStr
        categorySelected.value = productCategory.names.join(' > ')
        if (form.value.lngLat) {
          state.mapInfo.lnglat = form.value.lngLat.split(',').map((x) => Number(x))
          state.markers = [state.mapInfo.lnglat]
        }
      },
      { deep: true, immediate: true }
    )
    const categoryInitStr = ref('')
    const dateRange = ref(null)
    const serviceTimeValid = (rule, value, callback) => {
      if (value) {
        if (value === '1') {
          if (!form.value.validDay) {
            return callback(new Error('请选择'))
          } else {
            callback()
          }
        } else {
          if (!form.value.serviceStartTime || !form.value.serviceEndTime) {
            return callback(new Error('请选择'))
          } else {
            callback()
          }
        }
      } else {
        callback()
      }
    }
    const rules = ref({
      name: [
        { required: true, message: '请输入' },
        { max: 32, message: '请输入简短一些' },
      ],
      productGroupId: [{ required: true, message: '请选择' }],
      productCategoryId: [{ required: true, message: '请选择' }],
      picList: [{ required: true, message: '请上传商品图' }],
      origin: [
        { required: true, message: '请输入' },
        { max: 32, message: '请输入简短一些' },
      ],
      serviceTimeType: [
        { required: true, message: '请选择' },
        { validator: serviceTimeValid, trigger: 'blur' },
      ],
      productNo: [{ required: true, message: '请输入' }],
      logisticsUnifyPrice: [
        { pattern: /^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, message: '格式有误' },
      ],
      offShelfTime: [{ required: true, message: '请选择' }],
      calendarPriceFlag: [{ required: true, message: '请选择' }],
      shopLogisticsId: [],
      limitNum: [{ required: true, message: '请输入' }],
      bdFlag: [{ required: true, message: '请选择' }],
      txFlag: [{ required: true, message: '请选择' }],
    })

    function getLocation (res) {
      state.mapInfo.address = res.formattedAddress
      form.value.origin = res.formattedAddress
      state.mapInfo.lng = res.position.lng
      state.mapInfo.lat = res.position.lat
      state.mapInfo.lnglat = [res.position.lng, res.position.lat]
      if (!state.markers.length) {
        state.markers = [[res.position.lng, res.position.lat]]
      }
    }
    function mapEvent (e) {
      state.mapInfo.lng = e.lnglat.lng
      state.mapInfo.lat = e.lnglat.lat
      state.mapInfo.lnglat = [e.lnglat.lng, e.lnglat.lat]
      state.markers = [[e.lnglat.lng, e.lnglat.lat]]
      getFormattedAddress()
    }
    function initMapByInput () {
      AMap.plugin('AMap.Autocomplete', () => {
        let autoOptions = {
          city: '全国',
          input: 'tipinput',
        }
        let autoComplete = new AMap.Autocomplete(autoOptions)
        AMap.event.addListener(autoComplete, 'select', (e) => {
          console.log('通过输入拿到的地址', e)
          state.mapInfo.lat = e.poi.location.lat
          state.mapInfo.lng = e.poi.location.lng
          state.mapInfo.lnglat = [e.poi.location.lng, e.poi.location.lat]
          state.markers = [[e.poi.location.lng, e.poi.location.lat]]
          state.mapInfo.address = e.poi.name
          form.value.origin = e.poi.name
        })
      })
    }
    function getFormattedAddress () {
      AMap.plugin('AMap.Geocoder', () => {
        let GeocoderOptions = {
          city: '全国',
        }
        let geocoder = new AMap.Geocoder(GeocoderOptions)
        geocoder.getAddress(state.mapInfo.lnglat, (status, result) => {
          console.log('通过经纬度拿到的地址', result)
          if (status === 'complete' && result.info === 'OK') {
            state.mapInfo.address = result.regeocode.formattedAddress
            form.value.origin = result.regeocode.formattedAddress
          } else {
            state.mapInfo.address = '无法获取地址'
          }
        })
      })
    }
    function handleSyncFlagChange (val) {
      root.$confirm({
        title: '提示',
        content: `${
          val ? '将立即同步上级商品信息' : '关闭该功能会导致商品信息与上级商品信息不同步'
        }，确定继续吗？`,
        onOk: async () => {
          if (val) {
            const { code, msg } = await goods.productSync(props.initData.product.productId)
            if (code === '00000') {
              form.value.syncFlag = val
              emit('syncSuccess')
            } else {
              root.$message.error(msg || '提交失败，请稍后重试')
            }
          } else {
            form.value.syncFlag = val
          }
          emit('syncFlagChange', val)
        },
      })
    }
    async function changeProductType (e) {
      if (form.value.serviceTimeType !== '2') {
        form.value.serviceTimeType =
          e.target.value === '2' || e.target.value === '3' || e.target.value === '4' ? '1' : '0'
      }
      if (e.target.value === '0') {
        form.value.productTag = ''
      } else {
        form.value.productTag = '1,2,3'
      }
      if (e.target.value === '5') {
        root.$set(form.value, 'serviceTimeType', '2')
      }
      emit('productTypeChange', Number(e.target.value))
    }
    // 商品类目
    const categorySelected = ref('')
    const categorySettingShow = ref(false)
    const needVoucherFlag = ref(false)
    const needVoucher = ref({})
    function handleCategoryConfirm (data) {
      form.value.productCategoryId = data.code
      categorySelected.value = data.label
      formRef.value.validateField('productCategoryId')
      checkNeedVoucher()
    }
    async function checkNeedVoucher () {
      const levels = []
      const level = form.value.productCategoryId.length / 4 - 1
      for (let i = 0; i < level; i++) {
        if (i === 0) {
          levels.push(form.value.productCategoryId.substr(0, 8))
        } else {
          levels.push(form.value.productCategoryId.substr(0, 8 + i * 4))
        }
      }
      const { data, msg, code } = await goods.checkNeedVoucher({
        productCategoryId: levels.join(','),
      })
      if (code === '00000') {
        if (data) {
          if (data.type === '0') {
            needVoucherFlag.value = true
            needVoucher.value = data
            emit('needVoucherFlag:change', true)
            root.$confirm({
              title: '缺少经营凭证',
              content: data.content,
              okText: '去上传',
              onOk: () => {
                root.$router.push('/shop/voucher?id=' + data.manageVoucherId)
              },
            })
          } else {
            needVoucherFlag.value = true
            needVoucher.value = data
            emit('needVoucherFlag:change', true)
            root.$warning({
              title: '提示',
              content: '经凭证正在审核中，请审核通过后再发布此类商品。',
            })
          }
        } else {
          needVoucherFlag.value = false
          emit('needVoucherFlag:change', false)
        }
      } else {
        root.$message.error(msg || '凭证校验失败')
      }
    }
    // 物流模版
    watch(
      () => form.value.shopLogisticsMethod,
      (newVal) => {
        if (newVal === '2') {
          form.value.logisticsUnifyPrice = '0'
          rules.value.logisticsUnifyPrice = []
          rules.value.shopLogisticsId = [{ required: true, message: '请选择' }]
        } else {
          form.value.shopLogisticsId = undefined
          rules.value.logisticsUnifyPrice = [
            { required: true, message: '请输入' },
            {
              pattern: /^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
              message: '格式有误',
            },
          ]
          rules.value.shopLogisticsId = []
        }
      }
    )
    const shopLogisticsLoading = ref(false)
    const shopLogisticsList = ref([])
    async function getShopLogistics () {
      if (shopLogisticsLoading.value) return
      shopLogisticsLoading.value = true
      const { data } = await common.getShopLogistics()
      shopLogisticsLoading.value = false
      shopLogisticsList.value = data
        ? data.map((x) => ({ ...x, name: `${x.calcMethod === 1 ? '按件:' : '按重量:'}${x.name}` }))
        : []
    }
    function handleShopLogisticsChange () {
      form.value.shopLogisticsMethod = '2'
      formRef.value.clearValidate('shopLogisticsId')
    }

    // 商品分组
    const productGroupLoading = ref(false)
    const productGroupList = ref([])
    async function getProductGroupList () {
      if (productGroupLoading.value) return
      productGroupLoading.value = true
      const { data } = await common.getProductGroupByShopId()
      if (data && data.length && data[0].productGroupId && !form.value.productGroupId) {
        form.value.productGroupId = data[0].productGroupId
      }
      productGroupLoading.value = false
      productGroupList.value = data || []
    }

    // 提交校验
    const formRef = ref(null)
    const skuSettingRef = ref(null)
    function partValidate () {
      return new Promise((resolve, reject) => {
        formRef.value.validate((valid) => {
          if (valid) {
            resolve(form.value)
          } else {
            reject(new Error('校验失败'))
          }
        })
      })
    }
    async function formValidate () {
      try {
        const [
          product,
          {
            specList,
            specOptionList,
            skuList,
            stock,
            weight,
            changeFlag,
            anyPriceChangeFlag,
            ...prices
          },
        ] = await Promise.all([partValidate(), skuSettingRef.value.formValidate()])
        return {
          product: {
            ...pick(product, [
              'name',
              'type',
              'subTitle',
              'productGroupId',
              'productCategoryId',
              'state',
              'syncFlag',
              'virtualSale',
              'seqNum',
              'video',
              'productNo',
              'longFlag',
              'offShelfTime',
              'label',
              'bdFlag',
              'txFlag',
              'limitNum',
            ]),
            ...prices,
          },
          picList: product.video
            ? product.picList
              .map((x, i) => ({
                picPath: x,
                picMainFlag: !i,
              }))
              .concat({
                picPath: product.video,
                type: 1,
                picMainFlag: false,
              })
            : product.picList.map((x, i) => ({
              picPath: x,
              picMainFlag: !i,
            })),
          specList,
          specOptionList,
          skuList,
          productGeneral: {
            ...(props.initData && props.initData.productGeneral
              ? { productGeneralId: props.initData.productGeneral.productGeneralId }
              : {}),
            stock,
            weight,
            shopLogisticsId: product.shopLogisticsId || '',
            logisticsUnifyPrice: product.shopLogisticsId ? null : product.logisticsUnifyPrice,
            origin: product.origin,
            startNum: product.startNum,
            type: product.type,
            countryUnifyFlag: product.countryUnifyFlag,
            validDay: product.validDay,
            serviceTimeType: product.serviceTimeType,
            serviceStartTime: product.serviceStartTime,
            serviceEndTime: product.serviceEndTime,
            productTag: product.productTag,
            calendarPriceFlag: product.calendarPriceFlag,
            lngLat: state.mapInfo.lnglat.join(','),
          },
          changeFlag,
          anyPriceChangeFlag,
        }
      } catch (e) {
        console.error(e.message || '表单校验失败')
        return ''
      }
    }
    function addVideo () {
      showSelectVideo.value = true
    }
    function handleSelectDone (e) {
      if (e.length) {
        form.value.video = e[0]
      }
      showSelectVideo.value = false
    }
    function deleteVideo () {
      form.value.video = ''
      form.value = deepClone(form.value)
    }
    function deepClone (obj) {
      let objClone = Array.isArray(obj) ? [] : {}
      if (obj && typeof obj === 'object') {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            // 判断obj子元素是否为对象，如果是，递归复制
            if (obj[key] && typeof obj[key] === 'object') {
              objClone[key] = deepClone(obj[key])
            } else {
              // 如果不是，简单复制
              objClone[key] = obj[key]
            }
          }
        }
      }
      return objClone
    }
    function onChangeTag (value) {
      form.value.productTag = value.join(',')
      emit('efffectNotice', {
        productTag: value,
      })
    }
    function changeDateRange (value) {
      let formatS = form.value.type === '5' ? 'YYYY-MM-DD [00:00:00]' : 'YYYY-MM-DD HH:mm:ss'
      let formatE = form.value.type === '5' ? 'YYYY-MM-DD [23:59:59]' : 'YYYY-MM-DD HH:mm:ss'
      form.value.serviceStartTime = value.map((x) => moment(x).format(formatS))[0]
      form.value.serviceEndTime = value.map((x) => moment(x).format(formatE))[1]
      emit('efffectNotice', {
        serviceStartTime: value.map((x) => moment(x).format(formatS))[0],
        serviceEndTime: value.map((x) => moment(x).format(formatE))[1],
      })
    }
    function changeServiceTimeType (e) {
      emit('efffectNotice', {
        serviceTimeType: e.target.value,
      })
    }
    function changeValidDay (value) {
      emit('efffectNotice', {
        validDay: value,
      })
    }
    function changeValueBuStep3 (obj) {
      Object.assign(form.value, obj)
      dateRange.value =
        form.value.serviceStartTime && form.value.serviceEndTime
          ? [
            moment(form.value.serviceStartTime, 'YYYY-MM-DD HH:mm:ss'),
            moment(form.value.serviceEndTime, 'YYYY-MM-DD HH:mm:ss'),
          ]
          : null
    }
    return {
      noEditing: root.$route.path !== '/goods/publish/add',
      moment,
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      dateRange,
      changeDateRange,
      tagOptions: productTags,
      showSelectVideo,
      handleSelectDone,
      addVideo,
      deleteVideo,
      baseUrl: process.env.VUE_APP_IMG,
      formItemLayout,
      radioStyle,
      categoryInitStr,
      form,
      changeValidDay,
      rules,
      handleSyncFlagChange,
      categorySelected,
      categorySettingShow,
      handleCategoryConfirm,
      shopLogisticsLoading,
      shopLogisticsList,
      getShopLogistics,
      handleShopLogisticsChange,
      productGroupLoading,
      productGroupList,
      getProductGroupList,
      formRef,
      skuSettingRef,
      formValidate,
      needVoucherFlag,
      needVoucher,
      checkNeedVoucher,
      onChangeTag,
      changeServiceTimeType,
      changeProductType,
      changeValueBuStep3,
      ...toRefs(state),
    }
  },
}
</script>

<style lang="less" scoped>
.custom-spin {
  ::v-deep > div > .ant-spin .ant-spin-dot {
    left: initial;
    right: 18px;
  }
}
.sub-title {
  padding: 0 16px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
  line-height: 42px;
  background-color: #f3f6f8;
}
.category-result {
  display: flex;
  align-items: center;
  .result-text {
    height: 24px;
    padding: 0 2px;
    line-height: 24px;
    border-radius: 2px;
    border: 1px solid #c8c9cc;
  }
}
.custom-radio-group {
  width: 100%;
  padding-top: 1px;
  ::v-deep .ant-form-item {
    margin-left: 12px;
    margin-bottom: 0;
    .ant-form-item-control {
      display: flex;
      align-items: center;
      .ant-form-explain {
        margin-left: 6px;
      }
    }
    .ant-select {
      width: 203px;
    }
  }
}
.custom-radio-content {
  display: flex;
  align-items: center;
}
.operation-box {
  padding: 0 6px;
  ::v-deep .ant-btn {
    padding: 0 6px;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -1px;
      z-index: 1;
      width: 1px;
      height: 12px;
      margin-top: -6px;
      background-color: #999;
    }
    &:last-child::after {
      display: none;
    }
  }
}
.video-plus {
  width: 104px;
  height: 104px;
  border-radius: 4px;
  border: 1px dashed #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
  cursor: pointer;
  .desc {
    font-size: 14px;
    line-height: 14px;
    margin-top: 6px;
  }
}
.video {
  width: 104px;
  height: 104px;
  object-fit: contain;
}
.tips-box {
  font-size: 14px;
  line-height: 24px;
  .label {
    color: #000;
  }
  .value {
    color: #666;
  }
}
.tips-red {
  font-size: 14px;
  color: red;
  line-height: 24px;
}
.amap-demo {
  height: 300px;
}
</style>
